import React, { useState } from 'react';
import YouTube from 'react-youtube';
import Datos from '../assets/json/dataPlayer.json';
import '../assets/css/player.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faFacebookMessenger, faWhatsapp, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Button } from 'react-bootstrap';


function Player({ videos }) {
  const opts = {
    height: '220',
    width: '322',
  };

  const [showShareModal, setShowShareModal] = useState(null);
  const [videoLink, setVideoLink] = useState('');
  const [copiedMessageVisible, setCopiedMessageVisible] = useState(false);

  const toggleShareModal = (index, src) => {
    if (showShareModal === index) {
      setShowShareModal(null);
    } else {
      setShowShareModal(index);
      setVideoLink(src);
    }
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(videoLink);
    setCopiedMessageVisible(true);

    // Ocultar el mensaje después de 2 segundos
    setTimeout(() => {
      setCopiedMessageVisible(false);
    }, 2000);
  };

  return (
    <div className="video-container">
      {Datos.map((video, index) => (
        <div key={index} className='video-margen'>
          <YouTube videoId={getYouTubeVideoId(video.src)} opts={opts} />
          <p className='title-video2'>{video.captionText}</p>
          {/* <button className='btnShare' onClick={() => toggleShareModal(index, video.src)}>Compartir</button> */}
          <Button className='boton-contacto' onClick={() => toggleShareModal(index, video.src)}>Compartir</Button>

          {showShareModal === index && <ShareModal onClose={() => setShowShareModal(null)} videoLink={videoLink} copyLinkToClipboard={copyLinkToClipboard} copiedMessageVisible={copiedMessageVisible} />}
        </div>
      ))}
    </div>
  );
}

function getYouTubeVideoId(url) {
  const match = url.match(/[?&]v=([^&]*)/);
  return match && match[1];
}

function ShareModal({ onClose, videoLink, copyLinkToClipboard, copiedMessageVisible }) {
  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(videoLink)}`;
    window.open(facebookUrl, '_blank');
  };

  const shareOnMessenger = () => {
    const messengerUrl = `https://www.facebook.com/dialog/send?link=${encodeURIComponent(videoLink)}`;
    window.open(messengerUrl, '_blank');
  };

  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(videoLink)}`;
    window.open(whatsappUrl, '_blank');
  };

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(videoLink)}`;
    window.open(twitterUrl, '_blank');
  };

  return (
    <div className="share-modal">
      <div className="d-flex justify-content-end">
        <button onClick={onClose}>X</button>
      </div>
      <div className="text-center">
        <h4 style={{ color: 'white' }}>Compartir video</h4>

        <input type="text" value={videoLink} readOnly />
        <button onClick={copyLinkToClipboard} className="btn btn-link"><FontAwesomeIcon icon={faCopy} /></button>
        <div className="social-icons mt-2">
          
        <button className="mx-2" onClick={shareOnFacebook}>
            <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: '#4267B2' }} />
          </button>
          <button className="mx-2" onClick={shareOnMessenger}>
            <FontAwesomeIcon icon={faFacebookMessenger} size="2x"  style={{ color: '#B437C0' }} />
          </button>
          <button className="mx-2 text-success" onClick={shareOnWhatsApp}>
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </button>
          <button className="mx-2" onClick={shareOnTwitter}>
            <FontAwesomeIcon icon={faTwitter} size="2x"  style={{ color: '#1DA1F2' }} />
          </button>
        </div>
      </div>
      {copiedMessageVisible && <p className=' mg-4 text-success mx-auto pl-4 text-center'>Texto copiado Correctamente</p>}
    </div>
  );
}

export default Player;
