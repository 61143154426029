// Navbar.js
import React from 'react';
import { Container, Nav, Navbar, Button } from 'react-bootstrap';
import img from '../assets/img/icono.png';
import '../assets/css/navbar.css';
import { BsFacebook, BsYoutube} from "react-icons/bs";

function NavbarF({ formRef }) {
  const openNewTab = (url) => {
    window.open(url, '_blank');
  };

  const whatsappURL = 'https://wa.link/pkqxsi'; 


  return (
    <Navbar data-bs-theme="dark">
      <Nav>
        <Nav.Link><BsFacebook className="icono" onClick={() => openNewTab('https://www.facebook.com/Dujtv.ec/')} /></Nav.Link>
        <Nav.Link><BsYoutube className="icono" onClick={() => openNewTab('https://youtube.com/@dujtv6919?si=6n1BQcfAXZDAwe3x')} /></Nav.Link>
      </Nav>
      <Container>
        <Navbar.Brand href="https://www.dujtv.com/" className='mx-auto'>
          <img src={img} className="d-inline-block align-top responsive-logo" alt="icono central del menu" />
        </Navbar.Brand>
        <Button className='boton-contacto' href={whatsappURL} target="_blank">Contacto</Button>
      </Container>
    </Navbar>
  );
}

export default NavbarF;
