import React, { useState, useEffect } from 'react';
import '../assets/css/loader.css';
import logo from '../assets/img/icono.png';

const Loader = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 2500); // Cambiar a 3000 para que desaparezca después de 3 segundos
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isVisible && (
        <div className="loader-container">
          <div className="loader">
            <img src={logo} className="App-logo" alt="logo" />
            <div className="welcome-text">Bienvenido<span className="dots">...</span></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
