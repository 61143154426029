import React, { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Loader from './Components/loader.js';
import Navbar from './Components/navbar.js';
import Carrousel from './Components/carousel.js';
import Player from './Components/player.js';
//import Form from './Components/form.js';
import About from './Components/about.js';
import Footer from './Components/footer.js';

const App = () => {
  const formRef = useRef(null);

  return (
    <header>
      <div className="centrado-margen2">
        <Loader />
      </div>

      <div className="centrado-margen2">
        <Navbar formRef={formRef} />
      </div>

      <div className='centrado-margen2'>
        <Carrousel />
      </div>

      <div className="centrado-margen2">
        <Player />
      </div>
{/* 
      <div ref={formRef} className="centrado-margen2">
        <Form />
      </div> */}

      <div className="centrado-margen2">
        <About />
      </div>

      <div>
        <Footer />
      </div>
    </header>
  );
};

export default App;
