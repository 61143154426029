import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import aboutData from '../assets/json/dataAbout.json';
import '../assets/css/about.css';

function About() {
  const [accordionData, setAccordionData] = useState([]);

  useEffect(() => {
    setAccordionData(aboutData);
  }, []);

  return (
    <Accordion className="accordion" defaultActiveKey="">
      {accordionData.map((item, index) => (
        <Accordion.Item key={index} eventKey={index.toString()} className="accordion-item">
          <Accordion.Header className="accordion-header">{item.title}</Accordion.Header>
          <Accordion.Body className="accordion-body">
            {item.content}
            {item.title === "Contáctanos" && (
              <a className='url-whatsapp' href="https://wa.link/pkqxsi"> Contáctanos aquí</a>
            )}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default About;

//https://wa.link/pkqxsi