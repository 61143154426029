import Carousel from 'react-bootstrap/Carousel';
import '../assets/css/carousel.css'; 
import img1 from '../assets/img/carrousel/background.avif';
import img2 from '../assets/img/carrousel/hiphop.avif';
import img3 from '../assets/img/carrousel/rubio.avif';
function UncontrolledExample() {
  return (
    <Carousel className="carousel-container">
      <Carousel.Item>
        <img
          className="carousel-image"
          src={img1}
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h3>First slide label</h3> */}
          {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="carousel-image"
          src={img2}
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h3>First slide label</h3> */}
          {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="carousel-image"
          src={img3}
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h3>First slide label</h3> */}
          {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    
    
  );
}

export default UncontrolledExample;
