import React from 'react';
import { FaRegCopyright } from 'react-icons/fa';
import imagen2 from '../assets/img/rubio.png';
import '../assets/css/footer.css';

function Footer() {
  return (
    <footer className="footer-container"> <br></br>
      <div className="image-container">
        <img src={imagen2} alt="Imagen 2" className="imagen-logo-rubio" />
      </div>
      <div className="footer-text">
        <div className="footer-text-content">
          <FaRegCopyright />
          <span>Duj tv - 2024</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
